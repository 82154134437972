import githubIcon from '../images/url-icon/github-icon.png'
import linkedinIcon from '../images/url-icon/linkedin-icon.png'
import tstoryIcon from '../images/url-icon/tstory-icon.png'
import notionIcon from '../images/url-icon/notion-icon.png'
import appleAppstoreIcon from '../images/url-icon/apple-appstore.png'
import generalLink from '../images/icons/link.png'

import booksitoutIcon from '../images/icons/booksitout.png'
import boldPeopleIcon from '../images/icons/boldPeople.png'
import purrfitTrackerIcon from '../images/icons/purrfitTracker.png'
import substrackIcon from '../images/icons/substrack.png'
import springfeedIcon from '../images/icons/springfeed-logo.png'
import featuredDaysIcon from '../images/icons/featureddaysicon.png'

const WebsiteIcon = {
    github: githubIcon,
    tstory: tstoryIcon,
    linkedin: linkedinIcon,
    notion: notionIcon,
    appleAppstore: appleAppstoreIcon,
    link: generalLink,

    booksitout: booksitoutIcon,
    boldPeople: boldPeopleIcon,
    purrfitTracker: purrfitTrackerIcon,
    substrackIcon: substrackIcon,
    springfeed: springfeedIcon,
    featuredDays: featuredDaysIcon,
}

export default WebsiteIcon