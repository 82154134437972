import React from 'react'
import { Container } from 'react-bootstrap'

const InterestRoute = () => {
    return (
        <Container>

        </Container>
    )
}
export default InterestRoute
